import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPerformanceDateString } from "utils/DateFormatting";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { getBookingFeeText } from "utils/Text";
import styles from "./Success.module.css";

class SuccessView extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired, // state.auth
    order: PropTypes.object.isRequired, // state.order.item
    user: PropTypes.object.isRequired, // state.user.item
    settings: PropTypes.object.isRequired, // state.settings
  };

  componentDidMount = () => {
    if ("parentIFrame" in window) {
      window.parentIFrame.sendMessage({
        messageType: "orderSuccess",
        orderId: this.props.order.invoice.reference,
        orderTotal: this.props.order.total,
      });
    }
  };

  /**
   * Get html for the left panel
   *
   */
  getOrderInformationPanel = () => {
    let ticketDownloadUrl = this.props.order.receiptUrl;

    return (
      <div className={styles.panel}>
        <div className={styles.downloadTicketsContainer}>
          <a
            href={ticketDownloadUrl}
            className={`${styles.downloadTicketsButton} successDownloadTicketsButton`}
          >
            Download Receipts
          </a>
        </div>
        <div className={styles.successGuideText}>
          <p>Order reference: {this.props.order.invoice.reference}</p>
          <p>
            Your order confirmation will be sent to{" "}
            {this.props.order.customer.emailAddress}.
          </p>
          <p>
            <b>
              Please check your junk mail if they do not arrive within 2 hours.
            </b>
          </p>
          <p>
            Please contact the event organiser directly if you have any
            questions.
          </p>
        </div>
      </div>
    );
  };

  getSubtotal = () => {
    let total = 0;
    for (let purchase of this.props.order.ticketPurchases) {
      total += purchase.ticket.faceValueTotal * purchase.quantity;
    }
    return total;
  };

  getBookingFees = () => {
    let total = 0;
    for (let purchase of this.props.order.ticketPurchases) {
      total += purchase.ticket.outsideFeeTotal * purchase.quantity;
    }
    return total;
  };

  getDiscount = () => {
    let discount = 0;
    for (let purchase of this.props.order.ticketPurchases) {
      for (let receipt of purchase.receipts) {
        discount += receipt.discount;
      }
    }
    return discount;
  };

  getLineItem = (lineItem) => {
    let purchase = lineItem.map((purchase) => {
      let isFree =
        purchase.ticket.total === 0 || purchase.ticket.total == null
          ? true
          : false;
      let dateStr = getPerformanceDateString(purchase.performance);
      let currencySymbol = currencyCodeToSymbol(purchase.ticket.currency);
      let total = purchase.ticket.total;
      let groupedTotal = total * purchase.quantity;

      if (isFree) {
        return (
          <div
            key={purchase.id}
            className={`${styles.purchaseItem} successPurchaseItem`}
          >
            <div className={styles.purchaseInfo}>
              <div>
                <p>{dateStr}</p>
              </div>
              <div>
                <p>
                  {purchase.ticket.description} x {purchase.quantity}
                </p>
              </div>
              <p>
                {purchase.receipts.map((receipt, index) =>
                  receipt.seat ? (
                    <span className={styles.purchaseSeats} key={index}>
                      {receipt.seat}
                    </span>
                  ) : (
                    <></>
                  )
                )}
              </p>
            </div>
            <div className={styles.purchasePrice}>
              <p>FREE</p>
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={purchase.id}
            className={`${styles.purchaseItem} successPurchaseItem`}
          >
            <div className={styles.purchaseInfo}>
              <div>
                <p>{dateStr}</p>
              </div>
              <div>
                <p>
                  {purchase.ticket.description} ({currencySymbol}
                  {formatPrice(total)}) x {purchase.quantity}
                </p>
              </div>
              <p>
                {purchase.receipts.map((receipt, index) =>
                  receipt.seat ? (
                    <span className={styles.purchaseSeats} key={index}>
                      {receipt.seat}
                    </span>
                  ) : (
                    <></>
                  )
                )}
              </p>
            </div>
            <div className={styles.purchasePrice}>
              <p>
                {currencySymbol}
                {formatPrice(groupedTotal)}
              </p>
            </div>
          </div>
        );
      }
    });
    return (
      <div className={styles.eventSummary} key={lineItem[0].event.id}>
        <h4>{lineItem[0].event.title}</h4>
        {purchase}
      </div>
    );
  };
  /**
   * Get html for the right panel which shows the purchased order information
   *
   */
  getOrderBreakdownPanel = () => {
    let totalsSummary = null;

    let productPurchases = this.props.order.productPurchases.map((purchase) => {
      let isFree =
        purchase.total === 0 || purchase.total == null ? true : false;
      let currencySymbol = currencyCodeToSymbol(purchase.currency);
      let total = purchase.product.total;
      let groupedTotal = purchase.total;

      if (isFree) {
        return (
          <div key={purchase.id} className={styles.purchaseItem}>
            <div className={styles.purchaseInfo}>
              <div>
                <p>
                  {purchase.product.title} x {purchase.quantity}
                </p>
              </div>
            </div>
            <div className={styles.purchasePrice}>
              <p>FREE</p>
            </div>
          </div>
        );
      } else {
        return (
          <div key={purchase.id} className={styles.purchaseItem}>
            <div className={styles.purchaseInfo}>
              <div>
                <p>
                  {purchase.product.title} ({currencySymbol}
                  {formatPrice(total)}) x {purchase.quantity}
                </p>
              </div>
            </div>
            <div className={styles.purchasePrice}>
              <p>
                {currencySymbol}
                {formatPrice(groupedTotal)}
              </p>
            </div>
          </div>
        );
      }
    });

    // build list of tickets that were purchased
    const groupedPurchases = this.props.order.ticketPurchases.reduce(
      (rv, purchase) => {
        (rv[purchase.event.id] = rv[purchase.event.id] || []).push(purchase);
        return rv;
      },
      {}
    );
    const purchasedLineItems = Object.keys(groupedPurchases).map(
      (id) => groupedPurchases[id]
    );
    let purchases = purchasedLineItems.map((lineItem) =>
      this.getLineItem(lineItem)
    );

    // if user had to pay, show the totals
    if (this.props.order.total > 0 || this.props.order.discount > 0) {
      const symbol = currencyCodeToSymbol(this.props.order.currency);
      const subTotal = formatPrice(this.getSubtotal());
      const bookingFees = formatPrice(this.getBookingFees());
      const total = formatPrice(this.props.order.total);
      const feeText =
        this.props.order.tax > 0
          ? getBookingFeeText(this.props.order.currency)
          : "";
      const hasFees = bookingFees > 0;

      const discount = formatPrice(this.getDiscount());
      const hasDiscount = discount > 0;
      const donationAmount = this.props.order.donation
        ? this.props.order.donation.total
        : 0;

      var ticketDiscount;
      if (hasDiscount) {
        ticketDiscount = (
          <div className={styles.subTotal}>
            <p>Discount</p>
            <p className={styles.subTotalFigure}>
              - {symbol}
              {discount}
            </p>
          </div>
        );
      }
      if (hasFees) {
        totalsSummary = (
          <div className={styles.totals}>
            <div className={styles.subTotal}>
              <p>Subtotal</p>
              <p className={styles.subTotalFigure}>
                {symbol}
                {subTotal}
              </p>
            </div>
            <div className={styles.subTotal}>
              <p>Booking fees</p>
              <p className={styles.subTotalFigure}>
                {symbol}
                {bookingFees}
              </p>
            </div>
            {ticketDiscount}
            {donationAmount > 0 && (
              <div className={styles.subTotal}>
                <p>Donation</p>
                <p className={styles.subTotalFigure}>
                  {symbol}
                  {formatPrice(donationAmount)}
                </p>
              </div>
            )}
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>{" "}
              <span className={styles.totalValue}>
                {symbol}
                {total}
              </span>
            </div>
            <p className={styles.totalFooter}>{feeText}</p>
          </div>
        );
      } else {
        totalsSummary = (
          <div className={styles.totals}>
            {ticketDiscount}
            {donationAmount > 0 && (
              <div className={styles.subTotal}>
                <p>Donation</p>
                <p className={styles.subTotalFigure}>
                  {symbol}
                  {formatPrice(donationAmount)}
                </p>
              </div>
            )}
            <div className={styles.total}>
              <span className={styles.totalLabel}>Total</span>{" "}
              <span className={styles.totalValue}>
                {symbol}
                {total}
              </span>
            </div>
            <p className={styles.totalFooter}>{feeText}</p>
          </div>
        );
      }
    }

    return (
      <div className={styles.panel}>
        <h3>Order Summary</h3>
        {purchases}
        {productPurchases}
        {totalsSummary}
      </div>
    );
  };

  render = () => {
    return (
      <div className={styles.root}>
        <h2>Order success</h2>
        <div className={styles.row}>
          <div className={`${styles.orderInfoPanel} successOrderInfoPanel`}>
            {this.getOrderInformationPanel()}
          </div>
          <div className={styles.orderBreakdownPanel}>
            {this.getOrderBreakdownPanel()}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    order: state.order.item,
    user: state.user.item,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(SuccessView);
