import { useEffect } from "react";
import { fetchEventSettings, fetchStripeSettings } from "actions";
import { getEventId } from "selectors";

// Containers
import App from "./App";

// Components
import { Elements } from "@stripe/react-stripe-js";

// Utils
import { Switch, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Lato:400,300,700",
    },
  ],
};

const Main = () => {
  const dispatch = useDispatch();
  const eventId = useSelector(getEventId);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchStripeSettings());
    if (eventId) {
      dispatch(fetchEventSettings(eventId));
    }
  }, [dispatch, eventId]);

  if (!settings.stripeAccount) {
    return null;
  }
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: settings.stripeAccount,
  });

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <Switch>
        <Route component={App} />
      </Switch>
    </Elements>
  );
};

export { Main };
