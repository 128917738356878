import fetch from "isomorphic-fetch";
import buildUrl from "build-url";

export const fetchTicketsForPerformance = (performanceId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `performance/${performanceId}/tickets/`,
    queryParams: {
      resultsPerPage: 500,
    },
  });
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const fetchSeasonTicketsForPerformance = (performanceId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `performance/${performanceId}/season-tickets/`,
  });
  return fetch(url, {
    headers: {
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const getEvent = (eventId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `v1/event/${eventId}/`,
  });

  return fetch(url, {
    headers: {
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const getPerformances = (eventId, auth, page) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `v1/event/${eventId}/performances/`,
    queryParams: {
      resultsPerPage: 500,
      page: page,
    },
  });

  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const createUser = (userData, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "user/",
  });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify(userData),
  });
};

export const getUser = (auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `user/`,
  });
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.authorization,
    },
  });
};

export const updateUser = (userData, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "user/",
  });
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.authorization,
    },
    body: JSON.stringify(userData),
  });
};

export const createCard = (token, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "customer/cards/",
  });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.authorization,
    },
    body: JSON.stringify({
      stripeToken: token,
      default: true,
    }),
  });
};

export const selectCard = (cardId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "customer/cards/" + cardId + "/",
  });

  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.authorization,
    },
    body: JSON.stringify({
      default: true,
    }),
  });
};

export const authenticateUser = (authData, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "auth/",
  });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify({
      email: authData.email,
      password: authData.password,
    }),
  });
};

export const getCards = (auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "customer/cards/",
  });

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: auth.authorization,
    },
  });
};

export const createOrder = (
  reservation,
  auth,
  donation,
  charity,
  paymentMethod
) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "v1/order/",
  });

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: auth.authorization,
    },
    body: JSON.stringify({
      reservationId: reservation.item.reference,
      ...(paymentMethod && { paymentMethod: paymentMethod.id }),
      ...(donation.amount && {
        donation: {
          total: donation.amount,
          giftAid: donation.giftAid,
          ...(charity && { charity: charity }),
        },
      }),
    }),
  });
};

export const updateOrder = (auth, orderId, paymentIntentId) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "v1/order/",
  });

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: auth.authorization,
    },
    body: JSON.stringify({
      id: orderId,
      paymentIntentId,
    }),
  });
};

export const deleteCard = (cardId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "customer/cards/" + cardId + "/",
  });

  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: auth.authorization,
    },
  });
};

export const createReservation = (basketItems, auth, sessionId) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "reservation/",
  });

  let tickets = basketItems.tickets.allIds.map(
    (id) => basketItems.tickets.byId[id]
  );
  let seasonTickets = basketItems.seasonTickets.allIds.map(
    (id) => basketItems.seasonTickets.byId[id]
  );
  let products = basketItems.products.allIds.map(
    (id) => basketItems.products.byId[id]
  );
  let b = {
    ...(sessionId && { sessionId: sessionId }),
    basket: {
      tickets: tickets.map((item) => {
        return {
          quantity: item.quantity,
          ticketId: item.ticket.ticketId
            ? item.ticket.ticketId
            : item.ticket.id,
          ...(item.ticket.discountCode && {
            discountCode: item.ticket.discountCode,
          }),
          ...(item.seats.length && { seats: item.seats }),
        };
      }),
      seasonTickets: seasonTickets.map((item) => {
        return {
          quantity: item.quantity,
          ticketId: item.ticket.id,
          ...(item.seats.length && { seats: item.seats }),
        };
      }),
      products: products.map((item) => {
        return {
          quantity: item.quantity,
          productId: item.ticket.id,
        };
      }),
    },
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify(b),
  });
};

export const getBookingInformation = (eventId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "event/" + eventId + "/booking/",
  });

  return fetch(url);
};

export const sendForgotPasswordEmail = (email, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: "user/forgot-password/",
  });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify({
      email: email,
    }),
  });
};

export const updateReservation = (reservationRef, discountCode, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `reservation/${reservationRef}/discount/`,
  });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify({
      discountCode: discountCode,
    }),
  });
};

export const releaseSeatReservation = (
  seats,
  performanceId,
  publicKey,
  token
) => {
  const url = "https://app.seats.io/api/unreserve";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      objects: seats,
      eventKeys: [`${performanceId}`],
      publicKey: publicKey,
      reservationToken: token,
    }),
  });
};

export const refreshReservation = (reservationRef, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `reservation/${reservationRef}/`,
  });
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const getEventSettings = (eventId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `event/${eventId}/embed-settings/`,
  });
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const getForm = (formId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `form/${formId}/`,
  });
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

export const updateForm = (reservationRef, formId, formData, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `reservation/${reservationRef}/form/${formId}/`,
  });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.authorization,
    },
    body: JSON.stringify(formData),
  });
};

export const joinWaitingList = (email, performanceId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `performance/${performanceId}/waiting-list/`,
  });
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
    body: JSON.stringify({
      email: email,
    }),
  });
};

export const fetchPastOrders = (page, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `v1/customer/orders/`,
    queryParams: {
      resultsPerPage: 5,
      page: page,
    },
  });
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.authorization,
    },
  });
};

export const cancelTicket = (receiptId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `ticket-receipt/${receiptId}/`,
  });
  return fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + auth.authorization,
    },
  });
};

export const getProduct = (productId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `product/${productId}/`,
    queryParams: {
      apiKey: auth.apiKey,
    },
  });

  return fetch(url);
};

export const fetchProductsForCollection = (collectionId, auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `collection/${collectionId}/products/`,
    queryParams: {
      apiKey: auth.apiKey,
    },
  });

  return fetch(url);
};

export const getCollection = (collectionId, auth) => {
  const authorization = auth.authorization
    ? "Bearer " + auth.authorization
    : "Basic " + btoa(`${auth.apiKey}:`);
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `collection/${collectionId}/`,
  });
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};

export const getStripeSettings = (auth) => {
  const url = buildUrl(process.env.REACT_APP_API_SERVER, {
    path: `v1/stripe-settings/`,
  });
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${auth.apiKey}:`),
    },
  });
};

const Api = {
  getEvent: getEvent,
  getEventSettings: getEventSettings,
  fetchTicketsForPerformance: fetchTicketsForPerformance,
  getPerformances: getPerformances,
  getBookingInformation: getBookingInformation,
  createCard: createCard,
  authenticateUser: authenticateUser,
  getCards: getCards,
  createUser: createUser,
  createOrder: createOrder,
  updateOrder: updateOrder,
  deleteCard: deleteCard,
  createReservation: createReservation,
  selectCard: selectCard,
  sendForgotPasswordEmail: sendForgotPasswordEmail,
  updateReservation: updateReservation,
  releaseSeatReservation,
  fetchSeasonTicketsForPerformance,
  refreshReservation,
  updateUser,
  getUser,
  getForm,
  updateForm,
  joinWaitingList,
  fetchPastOrders,
  cancelTicket,
  fetchProductsForCollection,
  getProduct,
  getCollection,
  getStripeSettings,
};
export default Api;
