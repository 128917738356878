import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { push } from "connected-react-router";
import PaymentForm from "containers/PaymentForm";
import LaddaButton from "views/LaddaButton";
import BasketSummary from "components/BasketSummary";
import DonationOptions from "components/DonationOptions";
import DonationForm from "components/DonationForm";
import {
  createOrder,
  updateOrder,
  deleteLineItem,
  applyDiscountCode,
  addDonation,
  removeDonation,
  showOtherDonationForm,
  hideOtherDonationForm,
  removeGiftAid,
} from "actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencyCodeToSymbol, formatPrice } from "utils/Currency";
import { Link } from "react-router-dom";
import styles from "./Confirm.module.css";
import {
  getBasketCurrency,
  getTicketsInBasket,
  getSeasonTicketsInBasket,
  getIsPaymentRequired,
  getDonationAmount,
  getGiftAid,
  getProductsInBasket,
} from "selectors";
import { LinkButton } from "components/LinkButton";

export class ConfirmView extends React.Component {
  static propTypes = {
    performances: PropTypes.array.isRequired, // state.tickets.items
    discounts: PropTypes.array,
    ticketDiscount: PropTypes.number.isRequired,
    isOrdering: PropTypes.bool,
    isCreatingCardAndCheckingOut: PropTypes.bool,
    isApplyingDiscount: PropTypes.bool, // state.discount.isApplying
    ticketsByPerformance: PropTypes.object,
    isOrderComplete: PropTypes.bool,
  };

  componentWillMount = () => {
    let state = {
      noCardsOnMount: true,
    };
    this.setState(state);
  };

  /**
   * Called when user wants to change their default payment
   * method
   *
   */
  handleChangePaymentMethod = (evt) => {
    this.props.dispatch(push("/cards"));
  };

  /**
   * Called when the user clicks the buy now button, there must be a card
   * attached to the user's account
   *
   */
  handleCheckout = (evt) => {
    if (this.props.isOrdering || this.props.isCreatingCardAndCheckingOut) {
      return;
    }
    this.props
      .dispatch(createOrder())
      .then(() => this.props.dispatch(push("/success")));
  };

  /**
   * Called when the user clicks the buy now button and there's
   * credit card info that needs to be added to the user's account
   *
   */
  handleAddCardAndCheckout = (paymentMethod) => {
    return this.props.dispatch(createOrder(paymentMethod));
  };
  handleUpdateOrder = (paymentIntentId) => {
    return this.props.dispatch(updateOrder(paymentIntentId));
  };

  handleSubmitSuccess = () => {
    this.props.dispatch(push("/success"));
  };
  /**
   * Called when a reserved ticket is removed from the basket
   *
   */
  handleBasketItemRemove = (basketItem) => {
    this.props.dispatch(deleteLineItem(basketItem));
  };

  /**
   * Called when a discount code apply button is pressed
   *
   */
  handleApplyDiscountCode = (discountData) => {
    this.props.dispatch(applyDiscountCode(discountData.code));
  };

  handleShowDiscountClick = (e) => {
    e.preventDefault();
    const link = {
      pathname: "/add-discount",
      state: {
        modal: true,
        returnTo: "/confirm",
      },
    };
    this.props.dispatch(push(link));
  };

  handleDonationClick = (amount) => {
    return amount === this.props.donation
      ? this.props.dispatch(removeDonation())
      : this.props.dispatch(addDonation(amount));
  };

  handleOtherDonationClick = (values) => {
    this.props.dispatch(addDonation(values.amount));
    this.props.dispatch(hideOtherDonationForm());
  };
  handleShowOtherDonationAmount = () => {
    this.props.dispatch(showOtherDonationForm());
  };
  handleHideOtherDonationAmount = () => {
    this.props.dispatch(removeDonation());
    this.props.dispatch(hideOtherDonationForm());
  };
  handleAddGiftAid = (e) => {
    if (e.target.checked) {
      this.props.dispatch(push("/gift-aid"));
    } else {
      this.props.dispatch(removeGiftAid());
    }
  };

  handleBookingLinkClick = () => {
    const link = {
      pathname: "/booking-info",
      state: {
        modal: true,
        returnTo: "/confirm",
      },
    };
    this.props.dispatch(push(link));
  };

  getBookingConfirmation = () => {
    if (this.props.showBookingConfirmation) {
      return (
        <p
          className={`${styles.confirmationMessage} confirmConfirmationMessage`}
        >
          By clicking the Confirm and Buy button, I confirm that I have read and
          understood the{" "}
          <LinkButton onClick={this.handleBookingLinkClick}>
            booking information
          </LinkButton>{" "}
          associated with this event.
        </p>
      );
    }
    return "";
  };

  /**
   * Render right hand side payment panel
   *
   * If there is a default payment method attached, we'll do a straightforward
   * checkout, otherwise we'll prompt for a card then do a checkout on success
   *
   */
  renderPaymentPanel = () => {
    if (!this.props.isPaymentRequired) {
      return (
        <div>
          {this.getBookingConfirmation()}
          <LaddaButton
            loading={this.props.isOrdering}
            className={`${styles.orderButton} confirmOrderButton`}
            onClick={this.handleCheckout}
          >
            Confirm and Buy Now
          </LaddaButton>
        </div>
      );
    } else {
      let serverError = this.props.orderError;
      return (
        <div>
          {this.getBookingConfirmation()}
          <div className={styles.paymentHeader}>
            <h3>Payment Details</h3>
          </div>
          <PaymentForm
            onPaymentMethodSuccess={this.handleAddCardAndCheckout}
            onPaymentIntentSuccess={this.handleUpdateOrder}
            initialError={serverError}
          />
        </div>
      );
    }
  };

  render = () => {
    const { invoice } = this.props.order;

    if (invoice) {
      return <Redirect to={"/success"} />;
    }
    return (
      <div className={styles.root}>
        <div className={`${styles.backButton} confirmBackButton`}>
          <Link to={"/"}>
            <span className={`${styles.backButton} confirmBackButton`}>
              <FontAwesomeIcon icon="chevron-left" />
            </span>
            Back
          </Link>
        </div>
        <h2>Confirm your order</h2>
        <div className={styles.row}>
          <div className={`${styles.orderPanel} confirmOrderPanel`}>
            <BasketSummary
              ticketItems={this.props.ticketItems}
              seasonTicketItems={this.props.seasonTicketItems}
              productItems={this.props.productItems}
              currency={this.props.currency}
              handleBasketItemRemove={this.handleBasketItemRemove}
              ticketDiscount={this.props.ticketDiscount}
              feeDiscount={this.props.feeDiscount}
              donation={this.props.donation}
              onShowDiscountClick={this.handleShowDiscountClick}
              giftAid={this.props.giftAid}
            />
          </div>
          <div className={styles.paymentPanel}>
            {this.props.enableDonations && (
              <span>
                {!this.props.showOtherDonationForm && (
                  <DonationOptions
                    options={this.props.donationOptions}
                    currency={this.props.currency}
                    onDonationClick={this.handleDonationClick}
                    onOtherDonationClick={this.handleShowOtherDonationAmount}
                    currentDonation={this.props.donation}
                    donationText={this.props.donationText}
                  />
                )}
                {this.props.showOtherDonationForm && (
                  <DonationForm
                    currency={this.props.currency}
                    onSubmit={this.handleOtherDonationClick}
                    onCancelDonationClick={this.handleHideOtherDonationAmount}
                    currentDonation={this.props.donation}
                    donationText={this.props.donationText}
                  />
                )}
                {this.props.donation > 0 && this.props.enableGiftAid && (
                  <form>
                    <label>
                      <input
                        checked={this.props.giftAid}
                        type="checkbox"
                        onChange={this.handleAddGiftAid}
                        className={styles.giftAidCheckbox}
                      />
                      <div className={styles.giftAidCheckboxLabel}>
                        {`Add Gift Aid: Turn your ${currencyCodeToSymbol(
                          this.props.currency
                        )}${formatPrice(
                          this.props.donation
                        )} into ${currencyCodeToSymbol(
                          this.props.currency
                        )}${formatPrice(
                          this.props.donation * 1.25
                        )} at no extra cost`}
                      </div>
                    </label>
                  </form>
                )}
              </span>
            )}
            {this.renderPaymentPanel()}
          </div>
          <div className={styles.clearFix}></div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { order, performances, discount, ticketsByPerformance } = state;
  return {
    performances: performances.items,
    ticketsByPerformance: ticketsByPerformance,
    ticketItems: getTicketsInBasket(state),
    seasonTicketItems: getSeasonTicketsInBasket(state),
    productItems: getProductsInBasket(state),
    currency: getBasketCurrency(state),
    isPaymentRequired: getIsPaymentRequired(state),
    discounts: discount.items,
    ticketDiscount: discount.ticketDiscount,
    feeDiscount: discount.feeDiscount,
    orderError: order.createError,
    isCreatingCardAndCheckingOut: state.flow.isCreatingCardAndCheckingOut,
    isOrdering: state.order.isCreating,
    isApplyingDiscount: discount.isApplying,
    discountError: discount.discountError,
    enableDonations: state.settings.enableDonations,
    donation: getDonationAmount(state),
    donationOptions: state.settings.donationOptions,
    enableGiftAid: state.settings.enableGiftAid,
    giftAid: getGiftAid(state),
    charityName: state.settings.charityName,
    donationText: state.settings.donationText,
    showOtherDonationForm: state.donation.showOther,
    showBookingConfirmation: state.booking.item ? true : false,
    order: order.item,
  };
};

export default connect(mapStateToProps)(ConfirmView);
